
.advent-base {
	position: fixed;
	top: 60px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 101;
	pointer-events: none;

	.container {
		position: absolute;
	}

	.door-link {
		display: block;
		width: 80px;
		height: 80px;
		margin: 30px;
		pointer-events: all;

		.door-image {
			width: 100%;
			height: 100%;
		}
	}
}
