
@import "../../styles/vars.scss";

.logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
	.zoback-logo {
		width: 150px;

		@media screen and (min-width: $m-width) {
			width: 25%;
		}
	}
}
