
@import "src/styles/vars";
#buttons {
	text-align: right;
	margin-bottom: $m-el-padding;
}

@media (min-width: $m-width) {
	#buttons {
		margin-bottom: $d-el-padding;
	}
}
