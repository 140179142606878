
#backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #0008;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
