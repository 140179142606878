
@import "src/styles/vars";

#navbar-desktop {
	display: none;
}
#nav {
	position: sticky;
	z-index: 100;
	top: 0;
}
@media (min-width: $m-width) {
	#navbar-desktop {
		display: block;
	}
	#navbar-mobile {
		display: none;
	}
}
