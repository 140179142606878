
@import "src/styles/vars";

div.clickable-image {
	width: 100%;
	box-shadow: 0 0 15px rgba($dark, 0.2);
	border-radius: $border-radius-large;

	&:hover {
		cursor: pointer;
	}

	.thumbnail {
		display: block;
		margin: auto;
		padding: 20px;
	}
}
div#diagram {
	width: 100%;
}
.lightbox {
	img {
		width: 100%;
	}
}

@media screen and (min-width: $m-width) {
	div#diagram {
		display: grid;
		width: max-content;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
	}
	.clickable-image {
		border: 2px solid transparent;

		&:hover {
			border: 2px solid rgba($mint, 0.3);
		}

		.thumbnail {
			margin: 0;
			box-sizing: border-box;
			width: 100%;
		}
	}
}
