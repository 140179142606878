
@import "src/styles/vars";
h4 {
	margin: 10px 0;
}
.form-box {
	display: block;
	background-color: #0001;
	padding: 20px;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.form-box {
		border-radius: $border-radius-large;
		max-width: 700px;
	}
}
