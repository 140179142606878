
@import "src/styles/vars";

.error-popup {
	background-color: $red;
	color: #fff;
	display: block;
	padding: 10px;
	border-radius: $border-radius-small;
	position: absolute;
	bottom: -45px;
	left: 0;
	margin: 0 auto;
	width: max-content;
	box-sizing: border-box;
	z-index: 1;
	box-shadow: 3px 5px 20px #0004;

	&::before {
		content: "";
		background-color: $red;
		position: absolute;
		left: 40px;
		top: -7.5px;
		width: 15px;
		height: 15px;
		margin: 0 auto;
		transform: rotate(45deg);
	}

	@media screen and (max-width: $m-width) {
		width: 100%;
	}
}

#form-base {
	width: 100%;

	#form {
		max-width: 600px;
		background-color: #eee;
		box-sizing: border-box;
		padding: $d-el-padding $d-el-padding * 2;
		border-radius: $border-radius-large;
		box-shadow: 3px 3px 20px #0001;

		.field-box {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			.field-title {
				display: block;
				margin-top: 10px;

				.field-required {
					color: $red;
					font-weight: bold;
					margin-left: 5px;
				}
			}

			.space {
				height: 30px;
			}

			input {
				width: 100%;
				box-sizing: border-box;

				&[type="submit"] {
					width: max-content;
					height: max-content;
					margin-top: 20px;
					&.align-right {
						display: block;
						margin-left: auto;
						margin-right: 0;
					}
					&.span {
						width: 100%;
					}

					@media (max-width: $m-width) {
						&.mobile-span {
							width: 100%;
						}
					}
				}
			}

			.text-editor-container {
				background-color: #fff;
				margin-top: 5px;
			}

			&.wide {
				grid-column: 1 / -1;
			}
		}
	}

	&.bold .field-box .field-title {
		font-weight: bold;
	}

	&.wide #form {
		max-width: 100%;
	}

	@media (min-width: $m-width) {
		&.columns #form #form-content {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-column-gap: 20px;

			.field-box {
				grid-column: span 6;

				&.colspan-12 {
					grid-column: span 12;
				}

				&.colspan-5 {
					grid-column: span 5;
				}

				&.colspan-4 {
					grid-column: span 4;
				}

				&.colspan-3 {
					grid-column: span 3;
				}

				&.colspan-2 {
					grid-column: span 2;
				}

				&.colspan-1 {
					grid-column: span 1;
				}
			}
		}
	}

	@media (max-width: $m-width) {
		#form {
			width: 100%;
			box-sizing: border-box;
			border-radius: 0;
		}
	}
}
