
@import "@/styles/vars.scss";

.schedule-base {
	border: 1px solid #0002;
	border-radius: 10px;
	overflow: auto;

	.topbar {
		padding: 10px;
		display: flex;
		gap: 15px;
		justify-content: space-between;
		align-items: center;

		span,
		label {
			color: rgba($blue, 0.6);
		}

		input[type="date"] {
			font-size: 13pt;
			border: 2px solid $blue;
			box-shadow: 0 0 10px #0001;
		}
	}

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		background-color: rgba($blue, 0.1);
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba($blue, 0.4);
	}

	* {
		scrollbar-width: thin;
		scrollbar-color: rgba($blue, 0.4) rgba($blue, 0.1);
	}

	.schedule-main {
		padding: 10px;
		padding-bottom: 0;
		max-height: 35em;
		overflow: auto;

		.schedule {
			.head,
			.body {
				display: grid;
				padding: 10px;
				box-sizing: border-box;
				min-width: min-content;
			}

			.head {
				border: 2px solid $blue;
				box-shadow: 0 0 10px #0002;
				border-radius: 8px;
				font-weight: bold;
				// position: sticky;
				// top: 0;
				// z-index: 1;
				background-color: white;

				.head-item {
					margin: 0 25px;
				}
			}

			.body {
				padding-bottom: 0;
				margin-top: 20px;
				grid-auto-flow: column;
				grid-auto-rows: 2.5em;

				.body-item {
					height: 2em;
					border-top: 1px solid #01003c33;
				}

				.timestep-line {
					grid-column: 2 / -1 !important;
				}

				.timesteps-background {
					position: sticky;
					left: -3em;
					background-color: white;
					border-radius: 10px;
					z-index: 1;
					margin: -1em 0.5em -1em -3em;
					box-shadow: 0 0 10px #0003;
				}

				.timestep-item {
					position: sticky;
					left: 0;
					z-index: 2;
					border: none;
				}

				.timestep-item .timestep {
					position: absolute;
					top: -30%;
					color: rgba($blue, 0.5);
					font-size: small;
					font-family: "Roboto Mono", "Courier Prime", monospace;

					&.hour {
						font-size: medium;
						color: $blue;
					}
				}
			}
		}
	}

	.no-events {
		width: 100%;
		min-height: 35em;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $blue;
	}

	.date-controls {
		margin-left: 10px;
		display: inline-flex;
		gap: 10px;
		align-items: center;

		.date-control {
			display: inline-block;
			text-align: center;
			vertical-align: middle;
			width: 1em;
			height: 1em;
			padding: 5px;
			padding-top: 3px;
			border-radius: 100em;

			&:hover {
				cursor: pointer;
				background-color: #0003;
			}
		}
	}
}

input[type="date"],
select,
option {
	border: 1px solid #0002;
	padding: 5px 7px;
	border-radius: 5px;
	font-family: $app-sans-font;
	text-align: center;
	background-color: white;
}

@media screen and (max-width: $m-width) {
	.topbar {
		flex-direction: column;

		.date-controls {
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			& > *:first-child {
				flex-basis: 100%;
				text-align: center;
			}
		}
	}

	.schedule .body {
	}
}
