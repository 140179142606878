
@import "src/styles/vars";

table.item-list {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #efefef;

	th,
	td {
		padding: 10px;
	}

	thead tr {
		text-align: left;
		border-bottom: 1px solid #efefef;
	}

	tbody tr:not(.add-item-row) {
		transition: all $fade-time;

		&:nth-child(even) {
			background-color: rgba($light, 0.3);
		}

		&:hover {
			background-color: rgba($mint, 0.1);

			td.actions * {
				visibility: visible;
				opacity: 1;
			}
		}

		td.actions {
			text-align: right;
			display: flex;

			* {
				visibility: hidden;
				opacity: 0;
				transition: all $fade-time;
			}
		}
	}
	tbody tr.add-item-row {
		border-top: 1px solid #efefef;
	}
	tbody tr.add-item-row td {
		padding: 0;
	}
}

.edit-icon {
	color: #555;
	margin-right: 10px;
	font-size: 20px;
}
.delete-icon {
	color: $red;
	font-size: 20px;
}

.edit-icon,
.delete-icon {
	display: block;
	&:hover {
		cursor: pointer;
		color: #fff;
		text-shadow: 0 0 10px $mint;
	}
}

.delete-button {
	background: transparent;
	border: none;
}
