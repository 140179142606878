
@import "../styles/vars";

#img-container {
	background-color: $light;
	overflow: hidden;
	display: flex;
	align-items: baseline;
	justify-content: center;
	max-height: 200px;
	position: relative;

	img {
		display: block;
		width: 130%;
		left: 0;
	}

	.top {
		position: relative;
	}

	.bottom {
		position: absolute;
		left: 0;
	}

	.shadow {
		position: absolute;
		width: 200%;
		height: 100%;
		box-shadow: inset 0 0 20px 5px #000a;
		top: 0;
		left: -50%;
		pointer-events: none;
	}

	// .top.transparent {
	// 	opacity: 0;
	// }

	// .top.shown {
	// 	opacity: 1;
	// 	transition: opacity 250ms ease-in-out;
	// }

	// .bottom.transparent {
	// 	opacity: 0;
	// 	transition: opacity 250ms ease-in-out;
	// }

	// .bottom.shown {
	// 	opacity: 1;
	// }
}

@media (min-width: $m-width) {
	#img-container {
		max-height: 500px;

		img {
			width: 100%;
		}
	}
}
