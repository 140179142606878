
@import "../styles/vars";

#message-box {
	position: fixed;
	z-index: 1000;
	width: calc(100% - 30px);
	margin: 15px;
	padding: $d-el-padding $d-el-padding * 2;
	box-sizing: border-box;
	background-color: $white;
	box-shadow: 0 0 30px #0003;
	border-radius: $border-radius-large;

	h3 {
		margin: 10px 0;
	}
	p {
		margin-bottom: 10px;
	}

	&.warning {
		background-color: $yellow;
	}
	&.error {
		background-color: $red-vibrant;
		color: $white;
	}
	&.success {
		background-color: $mint;
		color: $white;
	}
}
//Animation
.msg-box-enter-active {
	animation: msg-box-enter 500ms;
}
.msg-box-leave-active {
	animation: msg-box-leave 500ms;
}

@keyframes msg-box-enter {
	0% {
		transform: translateY(-300px);
		opacity: 0;
	}
	50% {
		transform: translateY(20%);
	}
	100% {
		transform: none;
		opacity: 1;
	}
}
@keyframes msg-box-leave {
	0% {
		transform: none;
		opacity: 1;
	}
	20% {
		transform: translateY(40px);
	}
	100% {
		transform: translateY(-300px);
		opacity: 0;
	}
}
