
@import "src/styles/vars";

#group-feed,
#group-bio {
	width: 100%;
	overflow: hidden;

	::v-deep a {
		text-decoration: none;
		color: $red;
		text-decoration: none;
		transition: all 50ms;
	}
}
