
@import "../styles/vars";

.text-container {
	padding: $d-el-padding;
	background-color: #e9e9e9;
	border-radius: $border-radius-large;
	margin: $d-el-padding 0;
	white-space: pre-line;
}
@media (max-width: $m-width) {
	.borderless {
		border-radius: 0;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
