@import "src/styles/vars";

#diagram {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
	.column {
		.item-box {
			position: relative;
			background-color: #f7f7f7;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.title,
			.name,
			.date {
				flex-basis: 100%;
			}
			.name {
				color: saturate($blue, 50%);
				white-space: pre-wrap;
			}
			.title {
				font-weight: bold;
			}
			.since {
				font-size: 12px;
			}

			.tag {
				position: absolute;
				box-sizing: border-box;
				background-color: #0002;
				height: 100%;
				width: 5px;

				&.red {
					background-color: $red;
				}
				&.mint {
					background-color: $mint;
				}
				&.rose {
					background-color: $rose;
				}
				&.blue {
					background-color: $light-blue;
				}
				&.wie {
					background: repeating-linear-gradient(45deg, black, black 10px, white 10px, white 20px);
					border: 0.5px solid black;
				}
			}
		}
		.item-title {
			height: calc(5em + 20px);
		}
		.item {
			height: 3em;
		}
		.item-title,
		.item {
			padding: 10px;
			padding-left: 15px;

			.tag {
				margin-left: -15px;
			}
		}
		.span-2 {
			width: calc(200% + 10px - 25px);
			text-align: center;
		}

		.thumbnail {
			display: block;

			img {
				display: block;
				width: 100%;
			}
		}
	}
}

@media (min-width: $m-width) {
	#diagram {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media (max-width: 480px) {
	#diagram {
		font-size: 12px;
	}
}
