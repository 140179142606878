
@import "src/styles/vars";

input.styled {
	display: block;
	margin: $d-el-padding 0;
	width: calc(100% - 20px);
	background-color: #fff;
	font-size: 1em;

	&[disabled] {
		opacity: 0.7;
	}

	&::placeholder {
		font-style: normal;
	}
}

.hint,
.error {
	text-align: left;
	padding: 0 10px;
	font-size: 0.8em;
	line-height: 1em;
	margin-top: -10px;
	text-shadow: 0 0 5px #0002;
}
.hint {
	color: #aaa;
}
.error {
	color: $red-vibrant;
}
.nopadding {
	margin: 0 !important;
}
