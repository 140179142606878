
@import "../../styles/vars";

div#event-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 5px;

	div.event-widget {
		position: relative;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(
				217deg,
				lighten(desaturate(rgba(255, 30, 50, 0.8), 50), 30),
				rgba(255, 0, 0, 0) 70.71%
			),
			linear-gradient(
				127deg,
				lighten(desaturate(rgba(0, 255, 120, 0.8), 50), 30),
				rgba(0, 255, 0, 0) 70.71%
			),
			linear-gradient(336deg, lighten(rgba(60, 40, 255, 0.8), 15), rgba(0, 0, 255, 0) 70.71%);

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		.event-overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: all $fade-time;

			&:hover {
				background-color: #fff6;
			}

			span {
				display: block;
				text-align: center;
				padding: 5px;
				text-shadow: 0 0 15px #000c;

				&.event-name {
					font-size: 36px;
					font-weight: bold;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}
}

@media (min-width: $m-width) {
	div#event-list {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 10px;

		div.event-widget {
			height: 150px;
			grid-column: span 2;
			&:last-of-type {
				grid-column: 2 / span 2;
			}
		}
	}
}
