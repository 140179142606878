$blue: #3f4d5b;
$red: #ee7777;
$red-vibrant: #ee7777;
$dark: #01203a;
$light: #ecf1f3;
$white: #fff;
$light-grey: #e5e6eb;
$mint: #0fd8ac;
$yellow: #f7efab;
$rose: #ffdbff;
$light-blue: #93b7ff;
$cevi-blue: #323394;
$cevi-red: #c41333;

$fade-time: 150ms;

$m-page-padding: 25px;
$m-el-padding: 10px;
$m-width: 720pt;
$m-page-width: 480px;

$d-page-padding: 50px;
$d-el-padding: 15px;
$d-page-width: 1100px;

$border-radius-small: 5px;
$border-radius-large: 10px;

$app-sans-font: "Montserrat", "Trebuchet MS", "Open Sans", sans-serif;
$app-serif-font: "Lora", serif;
$app-font-size: 12pt;
