
@import "@/styles/vars.scss";

.enable-notifications {
	width: 1em;
	height: 1em;
	line-height: 1em;
	box-sizing: content-box;
	text-align: center;
}

.buttons {
	display: flex;
	gap: 10px;
}

.email-form {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

@media screen and (max-width: $m-width) {
	.email-form {
		flex-direction: column;
	}
}
