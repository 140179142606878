
@import "src/styles/vars";

.calendar {
	width: 100%;
	height: 400px;
}

#logo {
	width: 100%;
	margin-bottom: 50px;

	img {
		width: 100%;
	}
}

@media (min-width: $m-width) {
	.calendar {
		width: 50%;
		height: 450px;
	}

	#logo {
		width: 50%;
	}
}
