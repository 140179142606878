
@import "@/styles/vars.scss";

.base {
	padding: 10px 20px 20px 20px;
	margin-bottom: 30px;
	border: 2px solid #0002;
	border-radius: 8px;
	box-shadow: 2px 1px 20px #0002;

	color: $dark;

	.confirmation-amount {
		background-color: rgba($mint, 0.3);
		border-radius: 10px;
		padding: 10px 15px;
		border: 1px solid $mint;
		color: darken($mint, 20%);
		text-align: center;

		&.zero {
			color: darken($red, 20%);
			border-color: $red;
			background-color: rgba($red, 0.3);
		}
	}

	.confirmation-box {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		margin-top: 10px;

		.status {
			color: $white;
			padding: 7px 15px;
			background-color: $dark;
			border-radius: 6px;
			box-shadow: 2px 1px 15px #0003;
			font-weight: bold;
			text-transform: uppercase;

			&.accepted {
				background-color: $mint;
			}

			&.rejected {
				background-color: $red;
			}

			&.unconfirmed {
				background-color: $yellow;
				color: $blue;
			}

			&.past {
				background-color: $blue;
			}
		}
	}

	.details {
		display: grid;
		grid-template-columns: repeat(6, auto);
		gap: 15px 20px;
		overflow: auto;
		align-items: center;

		.title {
			// text-transform: uppercase;
			color: $blue;
		}

		.value {
			font-weight: bold;
			padding-right: 30px;
		}

		.remarks {
			padding: 7px;
			border: 1.5px solid #0002;
			border-radius: 8px;
			margin: 8px 0;
		}

		.small {
			grid-column: span 2;
		}

		.medium {
			grid-column: span 3;
		}

		.wide {
			grid-column: span 4;
		}

		.fill-row {
			grid-column: 1 / -1;
		}
	}
}

textarea.admin-toolbar {
	font-family: $app-sans-font;
	background-color: white;
	border: 1px solid rgba($blue, 0.2);
	margin: 0;
}

input[type="number"].admin-toolbar {
	border: 1px solid rgba($blue, 0.2);
	box-shadow: 0 0 15px #0001;
	border-radius: 5px;
	font-size: 20pt;
	max-width: 3em;
	text-align: center;

	&:disabled {
		content: "0";
	}
}

.admin-toolbar.action-select {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	height: 100%;

	input[type="radio"] {
		display: none;

		& + label {
			border: 1px solid rgba($blue, 0.2);
			padding: 5px;
			flex: 1;
			text-align: center;
			line-height: 1.5em;
			font-weight: bold;

			&:first-of-type {
				border-radius: 5px 5px 0 0;
			}

			&:last-of-type {
				border-radius: 0 0 5px 5px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		&:checked + label {
			background-color: $blue;
			color: white;

			&.green {
				background-color: $mint;
			}

			&.red {
				background-color: $red;
			}
		}
	}
}

#admin-form {
	display: flex;
	grid-column: 1 / -1;
	gap: 20px;

	& > * {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;
		gap: 10px;

		& > *:last-child {
			flex: 1;
		}
	}

	.block-action-select {
		flex-basis: 12em;
	}
}

input[type="submit"].admin-toolbar {
	margin: 0;
}

@media screen and (max-width: $m-width) {
	.base {
		box-sizing: border-box;

		.header {
			flex-direction: column-reverse;
			gap: 20px;
			align-items: flex-start;
			box-sizing: border-box;
		}

		.details {
			grid-template-columns: repeat(2, 1fr);
			grid-auto-flow: row;
			box-sizing: border-box;

			.small,
			.medium,
			.wide,
			.fill {
				grid-column: span 2;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.base .details * {
		grid-column: 1 / -1;
	}

	#admin-form {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		max-width: 100%;

		& > * {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
			gap: 10px;
			position: relative;
			max-width: 100%;
			box-sizing: border-box;

			& > *:last-child {
				flex: 0;
			}
		}

		.block-action-select {
			flex-basis: auto;
		}

		.block-num-assigned input[type="number"] {
			box-sizing: border-box;
			max-width: 100%;
		}
	}
}
