
@import "../styles/vars";

#container {
	width: 100%;
	box-sizing: border-box;
	background-color: desaturate($light, 5%);
	padding: 20px;
	margin-bottom: $d-el-padding;

	#buttons-container {
		button,
		a {
			width: 100%;
		}
	}
}

.desc {
	font-size: 14px;
	color: #0008;
}

#filepicker {
	font-family: $app-sans-font;
	display: block;
	margin: auto;
	padding: $m-el-padding 0;
}

@media (min-width: $m-width) {
	#container {
		border-radius: $border-radius-large;
		margin: 0 0 $d-el-padding 0;

		#buttons-container {
			display: flex;
			flex-wrap: wrap;
			margin: -5px;

			button,
			a {
				flex-basis: calc(25% - 10px);
				margin: 5px;
			}
		}
	}

	#filepicker {
		margin: $d-el-padding 0;
	}

	#btn-file-upload {
		width: calc(100% - 50px);
	}
}
