::v-deep article {
	@import "src/styles/vars";
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $app-sans-font;
	}

	.news-info-box {
		box-sizing: border-box;
		margin-bottom: 20px;
		background-color: rgba($cevi-blue, 0.25);
		border: 2px solid $cevi-blue;
		color: #000;

		.news-info-text {
			padding: 10px 20px;
			p {
				font-family: $app-sans-font;
			}

			h2 {
				margin-top: 10px;
			}
		}

		&.green {
			color: #000a;
			background-color: rgba($mint, 0.25);
			border: 2px solid $mint;
		}

		&.yellow {
			color: #000a;
			background-color: rgba($yellow, 0.25);
			border: 2px solid $yellow;
		}

		&.red {
			color: #000a;
			background-color: rgba($red, 0.25);
			border: 2px solid $red;
		}
	}

	.group {
		background-color: #eee;
		background-size: cover;
		background-position: center center;
		box-sizing: border-box;
		height: 120px;
		display: inline-block;
		width: calc(50% - 10px);
		font-size: 0;
		margin-right: 15px;

		& + .group {
			margin-right: 0;
		}

		a {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 20pt;
			font-family: $app-sans-font;
			font-weight: bold;
			position: relative;
			text-shadow: 0 0 10px #0008;

			&:hover::before {
				content: "";
				height: 100%;
				width: 100%;
				background-color: #fff7;
				position: absolute;
			}
		}

		@media (max-width: $m-width) {
			margin-bottom: 20px;
		}
	}
}
