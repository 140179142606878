
@import "src/styles/vars";

.player {
	width: 100%;
	height: 55vw;
}

@media (min-width: $m-width) {
	.player {
		width: 30vw;
		height: 20vw;
	}
}
