
@import "src/styles/vars";
.input-field-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	.input {
		flex: 1;
	}
	button {
		margin: 0;
		margin-left: $m-el-padding;
		align-self: flex-start;
	}
}

.add-button {
	width: 100%;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.add-button {
		width: unset;
	}
}
