
@import "../../styles/vars";

div#group-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 5px;

	div.group-widget {
		position: relative;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(
				217deg,
				lighten(desaturate(rgba(255, 30, 50, 0.8), 50), 30),
				rgba(255, 0, 0, 0) 70.71%
			),
			linear-gradient(
				127deg,
				lighten(desaturate(rgba(0, 255, 120, 0.8), 50), 30),
				rgba(0, 255, 0, 0) 70.71%
			),
			linear-gradient(336deg, lighten(rgba(60, 40, 255, 0.8), 15), rgba(0, 0, 255, 0) 70.71%);

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		.group-overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:hover {
				background-color: #fff6;
			}

			span {
				display: block;
				text-align: center;
				padding: 5px;
				text-shadow: 0 0 15px #000c;

				&.group-name {
					font-size: 36px;
					font-weight: bold;
				}
				&.group-year {
					font-size: 16px;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}

	div.empty {
		background: rgba(0, 0, 0, 0.05);
	}
}

@media (min-width: $m-width) {
	div#group-list {
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;

		div.group-widget {
			height: 150px;
			&:first-of-type {
				grid-column: 1 / -1;
			}
		}
	}
}
