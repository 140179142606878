
@import "../styles/vars";

button {
	margin: 5px 10px;
	font-weight: normal;
	width: 100%;
}

.title {
	margin-top: 0;
}

#container {
	width: 100%;
	box-sizing: border-box;
	background-color: $light-grey;
	border-radius: $border-radius-small;
	margin-bottom: 30px;
	padding: 20px;
}

#button-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	a {
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-basis: 100%;
		margin-bottom: 0;
	}
}

@media (max-width: $m-width) {
	#button-container {
		flex-direction: column;
	}
}
