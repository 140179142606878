
@import "src/styles/vars";

.link-card {
	display: block;
	padding: 10px;
	margin: 15px 0;
	position: relative;
	box-sizing: border-box;
	border-radius: $border-radius-small;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
	overflow: hidden;

	&::before {
		content: "";
		background-color: $red;
		width: 6px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
	}

	&:hover {
		box-shadow: 0 0 15px rgba($red, 0.2);
	}

	.link,
	.description {
		display: block;
		margin: 0 10px;
	}
	.link {
		text-transform: uppercase;
	}
	.description {
		color: #0007;
		padding-top: 10px;
		font-size: 14px;
		text-transform: capitalize;
	}
	.external-link-icon {
		display: none;
	}
}

@media (min-width: $m-width) {
	.link-card {
		display: grid;
		grid-template-columns: 1fr 2fr;
		padding: 12px;
		.link {
			text-transform: none;
		}
		.description {
			padding: initial;
			font-size: 15px;
		}
		.external-link-icon {
			display: block;
			color: $blue;
			position: absolute;
			right: 15px;
			align-self: center;
			opacity: 0;
			transition: all $fade-time;
		}

		&:hover {
			.external-link-icon {
				opacity: 0.7;
			}
		}
	}
}
