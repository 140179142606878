
@import "src/styles/vars";

.editions-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	.edition {
		img {
			width: 100%;
			box-shadow: 0 0 15px #0002;
		}

		.edition-info {
			.edition-title {
				font-size: 20px;
				font-weight: bold;
				color: $blue;
			}

			.edition-about {
				color: #0005;
			}
		}
	}
}

@media (min-width: $m-width) {
	.editions-container {
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 40px;
	}
}
