
@import "../styles/vars";

.text-box {
	background-color: #eee;
	max-width: 500px;
	margin: auto;
	padding: 20px;
	border-radius: $border-radius-large;
}
.error {
	position: relative;
	top: 10px;
}
.error-message {
	color: $red-vibrant;
	font-size: 1.2em;
	font-weight: bold;
}
label {
	position: relative;
}
h1 {
	color: $blue;
	margin-top: 20px;
}
form {
	text-align: center;
}
input {
	display: block;
	margin: $d-el-padding 0;
	width: calc(100% - 20px);
	background-color: #fff;
	font-size: 1em;

	&::placeholder {
		font-style: normal;
	}

	&[type="submit"] {
		max-width: 250px;
		margin: auto;
	}
}
#container {
	text-align: center;
	margin-bottom: 100px;
}
