
.timetable {
	td {
		padding: 3px 7px;

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}
	}
}
