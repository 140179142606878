
@use "sass:math";

@import "src/styles/vars";

$menu-item-h-padding: 8px;
$menu-item-h-margin: 10px;

#nav {
	font-size: 10pt;
}

#navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	min-height: 50px;
	background-color: $dark;
	padding: 0 50px;
	position: relative;
	// @supports (backdrop-filter: none) {
	// 	background-color: rgba($dark, 0.7);
	// 	backdrop-filter: blur(15px);
	// }
	box-shadow: 0 0 30px 5px #0007;

	#logo {
		img {
			width: 240px;
		}
	}

	#user {
		//Right align box
		width: max-content;
	}

	#menu {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		height: 100%;
		margin: 0.8em 0;

		.menu-item {
			margin: 5px $menu-item-h-margin;
			padding: 5px $menu-item-h-padding;
			transition: all $fade-time;
			display: flex;
			position: relative;

			color: #fff;

			&:hover {
				background-color: #fff;
				border-radius: $border-radius-small;

				.dropdown-container {
					max-height: 800px;
				}

				.menu-title {
					color: $dark;
					.dropdown-icon {
						transform: rotate(180deg);
					}
				}
			}

			.menu-title {
				.dropdown-icon {
					transition: transform $fade-time linear;
				}
			}

			.dropdown-container {
				position: absolute;
				left: -20px;
				right: 0;
				top: 0;
				max-height: 0;
				transition: max-height $fade-time * 2;
				z-index: 2;
				padding: 20px;

				&:hover .dropdown {
					box-shadow: 0 0 15px #0004;
					visibility: visible;
					max-height: 800px;
				}

				.dropdown {
					margin-top: 20px;
					background-color: #fff;
					color: #000;
					padding: 5px $menu-item-h-padding;
					border-radius: $border-radius-small;
					padding: 15px;
					transition: box-shadow $fade-time linear;
					width: min-content;
					visibility: hidden;
					max-height: 0px;
					transition: max-height $fade-time * 3;
					overflow: hidden;

					.dropdown-item {
						display: block;
						margin-bottom: 15px;
						transition: color math.div($fade-time, 2);
						color: #000;

						&:hover {
							color: $red;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
