
@import "src/styles/vars";

input[type="email"] {
	width: 100%;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	input {
		display: inline-block;
		max-width: 400px;
		margin-right: 20px !important;
	}
}
