
@import url("https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css");
@import "../styles/vars";

.nav-profile-container {
	padding-right: 20px;
}

@media screen and (max-width: $m-width) {
	div#nav-login-container {
		padding: $m-page-padding;

		span {
			color: rgba($white, 0.5);
		}

		input {
			display: inline;
			background-color: transparent;
			color: $white;
			font-size: medium;
			padding: 0;
		}

		:nth-child(2) {
			margin-left: 0;
		}
	}
}

.box {
	position: absolute;
	left: 0;
	right: 0;
	animation-duration: $fade-time * 2;
	text-align: center;
}

@media screen and (min-width: $m-width) {
	div#nav-login-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: max-content;
	}
	div#login-buttons-container a {
		width: max-content;

		&:first-of-type {
			margin-right: 10px;
		}
		&:last-of-type {
			margin-left: 10px;
			margin-right: 20px;
		}
	}
}
