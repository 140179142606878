
@import "../styles/vars";

#popup-background {
	animation-duration: 300ms;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba($dark, 0.6);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding-top: 80px;

	#popup-container {
		background-color: $white;
		padding: $m-el-padding;
		border-radius: $border-radius-large;
		flex-basis: calc(100% - 4 * #{$m-el-padding});
		min-height: 10vh;
		animation-duration: $fade-time;
		max-width: 100%;
		box-sizing: border-box;

		#topbar {
			#title {
				display: inline-block;
				margin-right: $d-el-padding;
				min-height: 2em;
			}

			#close-button {
				float: right;
			}

			.close-button {
				display: block;
				height: 16px;
				width: 16px;
				margin: -3px;
			}
		}

		#content {
			margin: $m-el-padding 0;
		}

		#buttons-container {
			text-align: right;
		}
	}
}

@media (min-width: $m-width) {
	#popup-background {
		align-items: center;

		#popup-container {
			flex-basis: auto;
			padding: $d-el-padding 2 * $d-el-padding;
			min-height: auto;
			max-width: 60vw;
			min-width: 300px;

			#content {
				display: block;
				margin: $d-el-padding 0;
			}
		}
	}
}
