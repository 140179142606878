
@import "src/styles/vars";

nav {
	color: #fff;
	width: 100%;
	position: sticky;
	z-index: 10;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: stretch;

	&.active {
		position: fixed;
		height: 100%;
	}

	#menubar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px $m-page-padding;
		padding-left: 0px;
		background-color: $dark;
		box-sizing: border-box;

		.home-link {
			height: 60px;
			max-width: 70%;

			img {
				height: 100%;
				width: 100%;
			}
		}
	}

	#menu-screen {
		background-color: desaturate(lighten($dark, 10%), 50%);
		box-sizing: border-box;
		overflow-y: scroll;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: space-between;

		animation-duration: $fade-time;

		@supports (backdrop-filter: none) {
			background-color: desaturate(rgba($dark, 0.8), 50%);
			backdrop-filter: blur(15px);
		}

		#user {
			background-color: $dark;
			align-items: center;
			align-self: center;
			width: 100%;
			box-sizing: border-box;
		}

		#menu {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			overflow-y: scroll;
			font-size: 18px;

			animation-duration: $fade-time;

			.btn-back {
				position: absolute;
				top: 20px;
				left: 20px;
				color: #fff;
				font-size: 25px;
				background-color: transparent;
				border: none;
			}

			.menu-item {
				display: block;
				width: 100%;
				box-sizing: border-box;
				color: #fff;
				text-align: center;
				padding: 20px;
				text-transform: uppercase;
				letter-spacing: 2.5px;
				font-weight: 400;

				span.title {
					display: inline-block;
				}
				i.expand-icon {
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
	}
}
