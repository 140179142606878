
.base {
	position: relative;
}
.request-button {
	width: 100%;

	a {
		width: 100%;
	}
}

.request-cards {
	position: relative;

	&.past {
		opacity: 0.6;
	}
}

.titlebar {
	display: flex;
	align-items: center;
	gap: 10px;
}
