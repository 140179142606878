
@import "../styles/vars";

.response-test-container {
	background-color: $dark;
	color: #eee;
	font-family: Consolas;
	white-space: pre-wrap;
	padding: 10px;
	border-radius: 10px;
}
