
@import "src/styles/vars";

.textarea {
	background-color: #fff;
	font-family: $app-sans-font;
	font-size: 16px;
	height: unset;
	min-height: 120px;

	&::placeholder {
		color: #aaa;
	}
}

input[type="date"] {
	background-color: #fff;
	border-radius: $border-radius-small;
	outline: none;
	border: none;
	padding: 8px;
	margin-bottom: $d-el-padding;
	color: #555;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}
@media (min-width: $m-width) {
	.textbox {
		max-width: 600px;
		margin: auto;
		overflow: auto;
	}
	.btn-send {
		float: right;
	}
}
