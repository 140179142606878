
@import "@/styles/vars.scss";

.cookie-base {
	position: fixed;
	inset: 0;
	pointer-events: none;
}

.cookie-banner {
	pointer-events: all;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $yellow;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba($blue, 0.2);
	border-bottom: none;
	box-shadow: 0 0 50px rgba($dark, 0.35);
}

button.styled {
	display: inline-block;
	margin: 0;
}
