
@import "src/styles/vars";

#container {
	display: grid;
	grid-template-areas:
		"avatar user user"
		"avatar actions actions";
	grid-column-gap: 10px;
	grid-template-columns: min-content 1fr 1fr;

	.username {
		grid-area: user;

		.username-text {
			color: $mint;
			font-weight: bold;
		}
	}

	.avatar {
		grid-area: avatar;
		background-color: #fffa;
		height: 35px;
		width: 35px;
		clip-path: circle(50%);

		.placeholder-icon {
			color: #000;
			width: 100%;
			height: 100%;
		}
	}
	.actions {
		grid-area: actions;
		display: flex;

		.action-button {
			color: #fff8;
			display: block;
			width: max-content;
			background-color: transparent;
			border: none;
			font-size: 14px;
			font-family: $app-sans-font;
			align-self: center;
			outline: none;
			padding: 0;
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}
