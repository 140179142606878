
@import "src/styles/vars";

.player {
	width: 100%;
	aspect-ratio: 16/9;

	iframe {
		width: 100%;
		height: 100%;
	}
}

@media (min-width: $m-width) {
	.player {
		display: block;
		width: 60%;
		margin: auto;
		height: auto;
	}
}
