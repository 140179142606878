
@import "src/styles/vars";

.tag {
	color: #888;
	font-size: 14px;
	display: block;
	margin-bottom: -7px;
	height: 32px;
}
.textarea {
	background-color: #fff;
	font-family: $app-sans-font;
	font-size: 16px;
	height: unset;
	min-height: 120px;

	&::placeholder {
		color: #aaa;
	}
}

.group-list {
	width: 100%;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.textbox {
		max-width: 700px;
		margin: auto;
		overflow: auto;
		padding: 20px;
	}
	.btn-send {
		float: right;
	}

	#data {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $d-el-padding * 2;
	}
}
