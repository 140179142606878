
@import "src/styles/vars.scss";
@import "src/styles/newsArticles.scss";

span.caption {
	color: #aaa;
	display: block;
	margin-bottom: 10px;
	font-family: $app-serif-font;
	text-align: right;
}

@media screen and (max-width: $m-width) {
	.borderless {
		width: calc(100% + #{$m-page-padding * 2}) !important;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
	}
}

.loading-skeleton {
	width: 100%;
}

@media screen and (min-width: $m-width) {
	.loading-skeleton {
		width: 70%;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.2s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
