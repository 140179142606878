
@import "src/styles/vars";
#groups-toggle-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: $m-el-padding;
}

@media (min-width: $m-width) {
	#groups-toggle-container {
		grid-template-columns: repeat(5, 1fr);
	}
}
