
@import "../styles/vars";

#page {
	background-color: desaturate($light, 50%);
	min-height: 60vh;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;

	.content {
		padding: 0;
		max-width: $d-page-width;
		background-color: $white;
		border-radius: $border-radius-small;
		overflow: hidden;
		border: 1px solid #0002;
		flex-basis: 100%;

		.body {
			padding: $d-page-padding;
		}

		&:not(:last-child) {
			margin-right: 20px;
		}
	}

	.aside {
		max-width: 350px;
		padding: 30px;
		height: max-content;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 30px;
			background-color: $dark;
		}
	}
}

@media screen and (max-width: $m-width) {
	#page {
		flex-direction: column-reverse;
		justify-content: flex-end;
		align-items: center;
		padding: 0;
		background: $white;
		min-height: 70vh;

		.content {
			margin-right: 0 !important;
			border: none;
			width: 100%;
			box-sizing: border-box;
			padding: $m-page-padding;
			overflow: auto;

			.body {
				padding: 0;
			}
		}

		.aside {
			margin: 0;
			max-width: $m-page-width;
			padding: $m-page-padding;

			&::before {
				display: none;
			}
		}
	}
}

#header-img-container {
	background-color: #eee;
	width: 100%;
	display: block;
	overflow: hidden;
	img {
		width: 200%;
		display: block;
		position: relative;
		right: 50%;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.2s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
