
@import "src/styles/vars";
#diagram {
	.row {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 2fr 2fr;
		grid-gap: 10px;
		margin-bottom: 10px;
		.field {
			padding: 10px 0;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
		.field.year {
			align-self: center;
		}
		.field.group {
			background-color: #f7f7f7;
			padding: 10px;
			padding-left: 15px;
			position: relative;

			&::before {
				content: "";
				width: 5px;
				background-color: #0003;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
			}
			&.male::before {
				background-color: $light-blue;
			}
			&.female::before {
				background-color: $rose;
			}

			.group-name {
				font-weight: bold;
				font-size: 1.1em;
				margin-bottom: 5px;
			}
			.group-lead {
				font-weight: lighter;
				color: saturate($blue, 50%);
			}
		}
	}
}

@media screen and (min-width: $m-width) {
	#diagram {
		display: flex;
		.row {
			margin-right: 10px;
			&:last-of-type {
				margin-right: 0;
			}
			grid-template-columns: unset;
			grid-template-rows: 1fr 2fr 2fr;

			.field.year {
				align-self: center;
			}
		}
	}
}

@media (max-width: 480px) {
	#diagram {
		font-size: 12px;
	}
}
