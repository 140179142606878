
@import "src/styles/vars";
.settings-list {
	.card {
		display: block;
		margin: 15px 0;
		padding: 10px;
		border-radius: $border-radius-large;
		box-shadow: 0 0 15px #0001;

		.value {
			color: $mint;
			font-weight: bold;
			float: right;
		}
	}
}

@media (min-width: $m-width) {
	.settings-list .card {
		max-width: 400px;
	}
}
