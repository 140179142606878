
@import "src/styles/vars";

.thumbnail {
	width: 100%;
	margin-bottom: $m-el-padding;
}

@media screen and (max-width: $m-width) {
	.borderless {
		width: calc(100% + #{$m-page-padding * 2}) !important;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
	}
}
