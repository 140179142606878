
@import "../styles/vars";

#technical-info {
	text-align: right;

	span {
		margin: 5px;
	}

	#version,
	#mode {
		opacity: 0.2;
		font-size: 12px;
	}
}

#footer {
	background-color: $dark;
	color: $white;
	padding: $m-page-padding;
	min-height: 200px;
	text-align: center;

	div#links {
		margin: 50px 0;
	}

	p {
		margin: 20px;
		color: $light;
	}

	a {
		display: block;
		margin: 20px auto;
		width: max-content;
	}

	.author {
		color: rgba($light, 0.7);
	}

	div#logo {
		background: url("../assets/svg/logo-cevi-notext-white.svg") no-repeat center center;
		margin: 10px 0 40px 0;
		filter: opacity(0.15);
		height: 100px;
		width: 100%;
		position: relative;
		z-index: auto;
	}
}

@media screen and (min-width: $m-width) {
	#footer {
		a {
			display: inline;
			margin: 20px;
		}
	}
}

#technical-info {
	text-align: center;
}
