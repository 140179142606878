
@import "src/styles/vars";

span.caption {
	color: #aaa;
	display: block;
	margin-bottom: 10px;
	font-family: $app-serif-font;
	text-align: right;
}
