
@import "@/styles/vars.scss";

.pwa-update-base {
	position: fixed;
	pointer-events: none;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;

	.container {
		pointer-events: all;
		position: absolute;
		background: white;
		border: 1px solid lighten($blue, 40%);
		bottom: 0;
		border-radius: 10px;
		display: flex;
		gap: 20px;
		align-items: center;
		padding: 0 10px;
		margin-bottom: 15px;
		box-shadow: 1px 2px 15px #0002;
	}
}
