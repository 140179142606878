
@import "src/styles/vars";

.input {
	width: 100%;
	box-sizing: border-box;
}
.input-descriptor {
	font-weight: bold;
	display: block;
	margin-bottom: 0;
	padding-bottom: 5px;
	color: #666;
}

textarea {
	margin-bottom: 20px;
	margin-top: 5px;
}

@media (min-width: $m-width) {
	.input {
		max-width: 400px;
	}
}
