
@import "src/styles/vars.scss";

.groups {
	display: flex;
	flex-direction: column;

	.group {
		background-color: #eee;
		background-size: cover;
		background-position: center center;
		margin: 10px 0;
		box-sizing: border-box;
		height: 120px;

		a {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 20px;
			font-weight: bold;
			position: relative;
			text-shadow: 0 0 10px #0008;

			&:hover::before {
				content: "";
				height: 100%;
				width: 100%;
				background-color: #fff7;
				position: absolute;
			}
		}
	}
}

@media (min-width: $m-width) {
	.groups {
		flex-direction: row;

		.group {
			flex-basis: 50%;
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}
	}
}
