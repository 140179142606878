
@import "src/styles/vars";

span.field-caption {
	font-weight: bold;
	font-size: 18px;
}
input.input {
	width: 400px;
	margin-bottom: 20px;
}
textarea.input {
	margin-top: 10px;
	font-size: 16px;
	font-family: $app-sans-font;
}
.filepicker {
	margin: 20px 0;
}
