
@import "@/styles/vars.scss";

.panels {
	display: grid;
	gap: 20px;
	grid-template-columns: minmax(20em, 5fr) minmax(7em, 20em);
	grid-auto-rows: minmax(30em, 43em) max-content;
	width: 100%;

	.title {
		font-weight: 400;
		color: $blue;
		text-transform: uppercase;
	}

	.list {
		display: flex;
		flex-direction: column;

		.list-items {
			margin: 10px 0;
			overflow-y: auto;
			padding: 10px;
			flex: 1;
			scrollbar-width: thin;

			.list-item:not(:last-child) {
				margin-bottom: 15px;
			}
		}

		.tabs {
			margin: 0 10px;
			border: 1px solid #0002;
			border-radius: 10px;
			display: flex;
			overflow: hidden;
			input[type="radio"] {
				display: none;
			}
			input[type="radio"] + label {
				flex: 1;
				text-align: center;
				padding: 5px 10px;
				word-wrap: wrap;
				word-break: break-all;
				&:hover {
					cursor: pointer;
				}
			}
			input[type="radio"]:checked + label {
				background-color: $mint;
				color: white;
				font-weight: bold;
			}
		}

		& > button {
			margin: 10px 10px 0 10px;
		}
	}
}

.details-modal-base {
	display: none;
	position: fixed;
	inset: 0;
	background-color: #0003;
	z-index: 200;
	backdrop-filter: blur(5px);
	padding: $m-page-padding;
	animation-duration: 200ms;

	.details-modal {
		background-color: white;
		width: 100%;
		min-height: 50%;
		max-height: 100%;
		padding: $m-page-padding;
		border-radius: 10px;
		box-sizing: border-box;
		overflow: auto;
		position: relative;

		.close {
			width: 1em;
			height: 1em;
			line-height: 1em;
			box-sizing: content-box;
			font-size: 16pt;
			padding: 5px;
			position: fixed;
			top: 0;
			right: 0;
			margin: 35px;
		}
	}
}

@media screen and (max-width: $m-width) {
	.panels {
		width: 100%;
		display: block;
	}

	.details {
		display: none;
	}

	.details-modal-base {
		display: block;
	}
}

.titlebar {
	display: flex;
	align-items: center;
	gap: 10px;

	h1 {
		margin: 0;
	}
}
