//GLOBAL STYLES
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100;0,400;0,700;0,900;1,400;1,700&display=swap");

@import "src/styles/vars";

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body,
html {
	margin: 0;
	padding: 0;
	font-family: $app-sans-font;
	font-size: $app-font-size;
}
h1 {
	font-size: 28pt;
}
h2 {
	font-size: 18pt;
}
h1 {
	color: $red;
	margin-top: 5px;
}

ul {
	list-style-type: disc;

	li:not(:last-child) {
		margin-bottom: 1em;
	}
}

p,
ul,
table {
	line-height: 1.75em;
	font-size: $app-font-size;
	font-family: $app-serif-font;

	&.sans {
		font-family: $app-sans-font;
	}
}
a {
	text-decoration: none;
}
.directus-page-content a,
a.styled {
	color: $red;
	text-decoration: none;
	transition: all 50ms;

	&:hover {
		border-bottom: 2px solid $red;
	}

	&:not([href]) {
		color: #000;

		&:hover {
			border-bottom: none;
		}
	}

	&.mint {
		color: $mint;
		border-bottom-color: $mint;
	}

	&.blue {
		color: $blue;
		border-bottom-color: $blue;
	}
}
a.nostyle {
	color: unset;
	border: none;

	&:hover {
		border: none;
	}
}

input[type="text"].styled,
input[type="email"].styled,
input[type="password"].styled,
input[type="date"].styled,
input[type="datetime-local"].styled,
input[type="number"].styled,
select.styled,
input.styled {
	background-color: $light-grey;
	border: none;
	outline: none;
	padding: 10px;
	border-radius: $border-radius-small;
	margin: 5px 0;
	font-size: 1em;
	font-family: $app-sans-font;
	box-shadow: 0 0 10px #0001;

	&::placeholder {
		color: #aaa;
	}

	&.white {
		background-color: #fff;
	}

	&.fullwidth {
		width: 100%;
		box-sizing: border-box;
	}

	&[disabled] {
		opacity: 0.7;
	}

	&.block {
		display: block;
	}
}
input[type="date"].styled,
input[type="datetime-local"].styled,
input[type="time"].styled {
	padding: 7px;
}
select.styled {
	padding: 9px;
	height: 39px;
}
input[type="submit"].styled,
input[type="reset"].styled,
a.button.styled,
button.styled {
	display: inline-block;
	width: auto;
	box-sizing: border-box;
	background-color: $dark;
	outline: none;
	border: none;
	color: $white;
	font-size: 1em;
	padding: 8px;
	border-radius: $border-radius-small;
	margin: 10px 0;
	font-weight: bold;
	transition: all $fade-time;
	text-align: center;
	font-family: $app-sans-font;
	box-shadow: 0 0 10px #0002;
	border: 2px solid transparent;

	&.padded {
		margin-right: 5px;
		margin-left: 5px;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	&:hover {
		border: 2px solid $dark;
		background-color: transparent;
		color: $dark;
		cursor: pointer;

		.lds-ring div {
			filter: invert(1);
		}
	}
	&[disabled] {
		opacity: 0.5;
		pointer-events: none;
	}

	&.nostyle {
		background-color: transparent;
		box-shadow: none;
		display: inline;
		font-size: 1em;
		padding: 0;
		margin: 0;

		&:hover {
			color: inherit;
		}
	}
	&.small {
		padding: 5px;
	}
	&.white {
		background-color: $white;
		color: $dark;

		&:hover {
			border: 2px solid $white;
			background-color: transparent;
			color: $white;
		}
	}

	&.light {
		background-color: $light;
		color: $dark;

		&:hover {
			border: 2px solid $light;
			background-color: transparent;
			color: $light;
		}
	}

	&.red {
		background-color: $red-vibrant;
		color: $white;

		&:hover {
			border: 2px solid $red-vibrant;
			background-color: transparent;
			color: $red-vibrant;
		}
	}

	&.blue {
		background-color: $blue;
		color: $white;

		&:hover {
			border: 2px solid $blue;
			background-color: transparent;
			color: $blue;
		}
	}

	&.mint {
		background-color: $mint;
		color: $white;

		&:hover {
			border: 2px solid $mint;
			background-color: transparent;
			color: $mint;
		}
	}

	&.white-bg:hover {
		background-color: $white;
	}

	@media (max-width: $m-width) {
		&.mobile-fullwidth {
			width: 100%;
			box-sizing: border-box;
		}
	}
}

textarea {
	background-color: $light-grey;
	border: none;
	outline: none;
	padding: 10px;
	margin: 5px 0;
	border-radius: $border-radius-small;
	box-sizing: border-box;
	resize: none;
	width: 100%;
	font-size: 1em;
	box-shadow: 0 0 10px #0001;
	font-family: $app-serif-font;

	&.white {
		background-color: #fff;
		color: $dark;

		&::placeholder {
			color: #0005;
		}
	}
}

hr {
	border: 1px solid #eee;
}
i.square {
	width: 1em;
	height: 1em;
}

@media screen and (min-width: $m-width) {
	.desktop-borderless {
		width: calc(100% + #{$d-page-padding * 2}) !important;
		margin-right: -$d-page-padding;
		margin-left: -$d-page-padding;
	}
}
@media screen and (max-width: $m-width) {
	.mobile-borderless {
		width: calc(100% + #{$m-page-padding * 2}) !important;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
	}

	button.mobile-fullwidth {
		box-sizing: border-box;
		width: 100%;
	}
}

$corona-yellow: #7a640d;
.corona {
	background-color: #ffff8491;
	padding: 20px;
	margin: 20px 0;
	font-family: $app-sans-font;
	color: $corona-yellow;
	border: 3px solid desaturate(lighten($corona-yellow, 60%), 15%);
	box-sizing: border-box;

	h3 {
		margin-top: 0;
	}
}

.button-link {
	background: none;
	border: none;
	color: $red;
	font-size: $app-font-size;
	font-family: $app-sans-font;
	padding: 0;
	margin: 0;
	display: inline;
	border-radius: 0;

	&:hover {
		border-bottom: 2px solid $red;
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}
}
