
@import "@/styles/vars.scss";

.card {
	$anim: 200ms;
	background-color: $dark;
	color: white;
	box-shadow: 0 0 20px #000a, 0 0 10px #000a;
	outline: 3px solid black;
	border-radius: 8px;
	padding: 10px;
	margin: 0 15px;
	transition: outline $anim, color $anim;
	position: relative;
	overflow: auto;

	&.mobile {
		padding: 5px;
		margin: 0 8px;
	}

	&.past {
		color: #fff8;
	}

	.items {
		margin-top: 10px;
		display: grid;
		grid-template-columns: min-content auto;
		grid-auto-rows: min-content;
		gap: 6px 12px;
		align-items: center;

		span {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&:hover {
		cursor: pointer;
	}

	&.selected {
		outline: 3px solid $light-blue;
		color: $light-blue;

		&.accepted {
			outline-color: $mint;
			color: $mint;
		}

		&.rejected {
			outline-color: $red;
			color: $red;
		}

		&.pending {
			outline-color: $yellow;
			color: $yellow;
		}

		&.past {
			outline-color: white;
			color: white;
		}
	}

	.line:not(:last-of-type) {
		margin-bottom: 5px;
	}

	.badge {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 12px;
		height: 12px;
		background-color: $light-blue;
		border-radius: 100px;
		&.past {
			background-color: $blue;
		}
		&.accepted {
			background-color: $mint;
		}
		&.rejected {
			background-color: $red;
		}
		&.pending {
			background-color: $yellow;
		}
	}

	.overflow,
	.underflow {
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		gap: 20px;

		i {
			opacity: 0.7;
		}
	}

	.overflow {
		position: absolute;
		bottom: 10px;
	}
}
