
@import "src/styles/vars";

.map {
	width: 100%;
	height: 400px;
}

@media (min-width: $m-width) {
	.map {
		width: 50%;
		height: 450px;
	}
}
