
@import "src/styles/vars";

select,
option {
	font-size: 14px;
}

.list-item-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	& > * {
		flex: 1 1 100%;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}
	}

	.buttons {
		max-width: max-content;

		& > button:not(:last-child) {
			margin-right: 10px;
		}
	}

	.mat-amount {
		flex: 0;
		width: 80px;
		margin-right: 10px;
	}
}

.info-button {
	position: relative;

	.description-box {
		display: none;
		opacity: 0;
		position: absolute;
		bottom: calc(100% + 10px);
		right: 0;
		background: $white;
		border: 0.5px solid #0002;
		box-shadow: 2px 4px 15px #0002;
		border-radius: $border-radius-small;
		padding: 15px;
		width: 300px;
		transition: all 250ms;

		.desc h4 {
			text-align: left;
			margin: 0;
			margin-bottom: 7px;
		}

		.desc p {
			font-weight: normal;
			line-height: 1.2rem;
			text-align: left;
			margin: 0;
		}

		.desc:not(:last-of-type) {
			margin-bottom: 15px;
		}
	}

	&:hover .description-box {
		display: block;
		opacity: 1;
	}
}

@media (max-width: $m-width) {
	.list-item-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 7px;
		width: 100%;
		margin-bottom: 20px;
		overflow-y: visible;

		& > * {
			margin: 0 !important;
			width: 100% !important;
		}

		.mat-amount {
			box-sizing: border-box;
		}

		.buttons .description-box {
			right: -130px;
		}
	}
	.add-button {
		width: 100%;
	}
}
